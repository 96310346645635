import { api } from '@/plugins/axios'
import { mapState } from 'vuex'
import moment from 'moment'
export default {
  data () {
    return {
      imageRootUrl: process.env.VUE_APP_IMAGE_URL,
      webUrl: process.env.VUE_WEB_URL,
      profileKey: 0,
      currentDate: moment().format('YYYY-MM-DD')
    }
  },
  computed: {
    ...mapState(['tenant', 'userDetails', 'token', 'appSettings', 'tabLogo']),
    xsOnly () {
      return this.$vuetify.breakpoint.xsOnly
    },
    breakpoint () {
      return this.$vuetify.breakpoint.name
    },
    weeksList () {
      return [
        { name: 'First', value: 1 },
        { name: 'Second', value: 2 },
        { name: 'Third', value: 3 },
        { name: 'Fourth', value: 4 },
        { name: 'Last', value: 5 }
      ]
    },
    weekDays () {
      return [
        { name: 'Monday', value: 1 },
        { name: 'Tuesday', value: 2 },
        { name: 'Wednesday', value: 3 },
        { name: 'Thursday', value: 4 },
        { name: 'Friday', value: 5 },
        { name: 'Saturday', value: 6 },
        { name: 'Sunday', value: 0 },
      ]
    },
    roomTypesList () {
      return [
        { name: 'Classroom', value: 1 },
        { name: 'Laboratory', value: 2 },
        { name: 'Hall', value: 3 }
      ]
    },
    listOfSections () {
      return ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
    },
    monthsList () {
      return [{
        text: 'January',
        value: 1
      }, {
        text: 'February',
        value: 2
      }, {
        text: 'March',
        value: 3
      }, {
        text: 'April',
        value: 4
      }, {
        text: 'May',
        value: 5
      }, {
        text: 'June',
        value: 6
      }, {
        text: 'July',
        value: 7
      }, {
        text: 'August',
        value: 8
      }, {
        text: 'September',
        value: 9
      }, {
        text: 'October',
        value: 10
      }, {
        text: 'November',
        value: 11
      }, {
        text: 'December',
        value: 12
      }]
    }
  },
  methods: {
    // api methods
    $_execute (method, url, payload, config) {
      return api[method](url, payload, config)
    },
    // general
    $_copy (data) {
      return data ? JSON.parse(JSON.stringify(data)) : null
    },
    $_valid (data) {
      return data && data.length ? data : ''
    },
    $_isArray (data) {
      return Array.isArray(data)
    },
    $_getRandomFromArray(array) {
      return array[Math.floor(Math.random() * array.length)]
    },
    // validations
    setValidation (type) {
      var result = []
      switch (type) {
        case 'requiredValidation':
          result = [
            v => !!v || 'Required',
            v => !/^\s*$/.test(v) || 'Required'
          ]
          break
        case 'emailValidation':
          result = [ 
            v => !!v || 'Required',
            v => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'Invalid Email!'
          ]
          break
        case 'numberValidation':
          result = [
            v => !!v || 'Required',
            v => /^([\d+]{1, 12})$/.test(v) || 'Invalid Number'
          ]
          break
        case 'wholeNumberValidation':
          result = [
            v => !!v || 'Required',
            v => /^([0-9]*)$/.test(v) || 'Invalid Number!'
          ]
          break
        case 'stringValidation':
          result = [
            v => !!v || 'Required',
            v => /^([\D+]{1, 20})$/.test(v) || 'Invalid Name!'
          ]
          break
        case 'noSpaceValidation':
          result = [
            v => !!v || 'Required',
            v => /^\S+$/.test(v) || 'Spaces are not allowed!'
          ]
          break
        default:
          result = []
          break
      }
      return result
    },
    positiveNumberValidation () {
      // this is not working
      // const value = event.target.value + event.key
      // if (!(/^[1-9]|[1-9]0+$/.test(value))) event && event.preventDefault()
    },
    positiveNumberAndCommaOnlyValidation (event) {
      if (!(/^([0-9, ])$/.test(event.key))) event && event.preventDefault()
    },
    // date methods
    $_formatDate (date, fromFormat, toFormat) {
      if (!date) return null
      return moment(date, (fromFormat || 'YYYY-MM-DDTHH:mm:ss')).format(toFormat || fromFormat || 'DD.MM.YYYY')
    },
    $_utcToLocal (date, fromFormat = 'YYYY-MM-DDTHH:mm:ss', toFormat) {
      return date ? moment(moment.utc(date, fromFormat).toDate()).format(toFormat || fromFormat) : null
    },
    $_localToUtc (date, fromFormat = 'YYYY-MM-DDTHH:mm:ss', toFormat) {
      return date ? this.$moment.utc(this.$moment(date, fromFormat)).format(toFormat || fromFormat) : null
    },
    $_formatModelToUtcDate ({ model, keys = [], from = 'YYYY-MM-DDTHH:mm', to = 'YYYY-MM-DDTHH:mm' }) {
      if ((model && keys.length && from && to) && typeof model === 'object') {
        keys.map((key) => {
          model[key] = (model[key] && this.$moment(model[key], from).isValid())
            ? this[(process.env.VUE_APP_ENV === 'production') ? '$_localToUtc' : '$_formatDate'](model[key], from, to)
            : null
        })
      }
      return model
    },
    $_filterByDateTime (array, { start, end }) {
      let result = []
      array.forEach((event) => {
        const eventFrom = moment(event.start).format('YYYY-MM-DDTHH:mm')
        const eventTo = moment(event.end).format('YYYY-MM-DDTHH:mm')
        if (moment(moment(start, 'YYYY-MM-DDTHH:mm')).isSame(moment(eventFrom, 'YYYY-MM-DDTHH:mm')) || moment(moment(start, 'YYYY-MM-DDTHH:mm')).isSame(moment(eventTo, 'YYYY-MM-DDTHH:mm'))) result.push(event)
        else if (moment(moment(end, 'YYYY-MM-DDTHH:mm')).isSame(moment(eventFrom, 'YYYY-MM-DDTHH:mm')) || moment(moment(end, 'YYYY-MM-DDTHH:mm')).isSame(moment(eventTo, 'YYYY-MM-DDTHH:mm'))) result.push(event)
        else if (moment(moment(start, 'YYYY-MM-DDTHH:mm')).isBetween(moment(eventFrom, 'YYYY-MM-DDTHH:mm'), moment(eventTo, 'YYYY-MM-DDTHH:mm')) || moment(moment(end, 'YYYY-MM-DDTHH:mm')).isBetween(moment(eventFrom, 'YYYY-MM-DDTHH:mm'), moment(eventTo, 'YYYY-MM-DDTHH:mm'))) result.push(event)
        else if (moment(moment(eventFrom, 'YYYY-MM-DDTHH:mm')).isBetween(moment(start, 'YYYY-MM-DDTHH:mm'), moment(end, 'YYYY-MM-DDTHH:mm')) || moment(moment(eventTo, 'YYYY-MM-DDTHH:mm')).isBetween(moment(start, 'YYYY-MM-DDTHH:mm'), moment(end, 'YYYY-MM-DDTHH:mm'))) result.push(event)
      })
      return result
    },
    $_localToUtcUnix (date, fromFormat = 'YYYY-MM-DDTHH:mm') {
      return moment(this.$_localToUtc(date, fromFormat, 'YYYY-MM-DDTHH:mm'), 'YYYY-MM-DDTHH:mm').unix()
    },
    $_utcUnixToLocalDateTime (unix, toFormat) {
      return this.$_utcToLocal(moment.unix(unix).format('YYYY-MM-DDTHH:mm'), 'YYYY-MM-DDTHH:mm', toFormat || 'YYYY-MM-DDTHH:mm')
    },
    $_calculateElapsedMillisecondsToHour(clockinTime) {
      var elapsedTime = moment() - clockinTime 
      elapsedTime = elapsedTime / 1000 // Convert time difference from milliseconds to seconds
      let seconds = Math.floor(elapsedTime % 60) // Pad seconds with a zero (if necessary) and convert to string
      let secondsAsString = seconds < 10 ? "0" + seconds : seconds;
      elapsedTime = Math.floor(elapsedTime / 60) // Convert time difference from seconds to minutes using %
      let minutes = elapsedTime % 60      // Extract integer minutes that don't form an hour using %
      let minutesAsString = minutes < 10 ? "0" + minutes : minutes      // Pad minutes with a zero (if necessary) and convert to string
      elapsedTime = Math.floor(elapsedTime / 60)      // Convert time difference from minutes to hours
      let hours = elapsedTime % 24      // Extract integer hours that don't form a day using %
      elapsedTime = Math.floor(elapsedTime / 24)      // Convert time difference from hours to days
      let days = elapsedTime      // The rest of elapsedTime is number of days
      let totalHours = hours + (days * 24); // add days to hours
      let totalHoursAsString = totalHours < 10 ? "0" + totalHours : totalHours;
      return `${totalHoursAsString}:${minutesAsString}:${secondsAsString}`
    },
    $_getRecordType (model) {
      switch (model) {
        case 'userAccount':
          return 'user'
        case 'classes':
          return 'class'      
        case 'groups':
          return 'group'      
        default:
          return ''
      }
    },
    $_groupBy (array, key) {
      return array.reduce(function (total, item) {
        total[item[key]] = total[item[key]] || []
        total[item[key]].push(item)
        return total
      }, {})
    },
    $_formatModelForSelectField (fields, data, isConstruct) {
      if (fields && fields.length) {
        const selectFields = fields.filter(x => x.type === 'SELECT')
        if (selectFields && selectFields.length) {
          selectFields.map(field => {
            if (isConstruct) {
              if (data.formdata[field.model]) {
                if (field.isMultiselect) {
                  //
                } else {
                  data.formdata[field.model] = [ data.formdata[field.model] ]
                }
              }
            } else {
              if (data.formdata[field.model]) {
                if (field.isMultiselect) {
                  data.formdata[field.model] = data.formdata[field.model]
                } else {
                  data.formdata[field.model] = data.formdata[field.model][0]
                }
              }
            }
          })
        }
      }
      return data
    },
    $_getNewObjectId () {
      return new Promise((resolve) => {
        this.$_execute('get', 'get-new-object-id').then(({ data }) => {
          resolve(data)
        })
      })
    }
  }
}