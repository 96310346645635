export default {
  userDetails: {},
  appSettings: {},
  tenant: '',
  token: null,
  // profile: '',
  tabLogo: '',
  userRoles: [],
  filters: {},
  activeUsers: [],
  listOfModules: [{
    name: 'attendance'
  }, {
    name: 'library'
  }, {
    name: 'calendar'
  }, {
    name: 'leave_management'
  }, {
    name: 'settings'
  }, {
    name: 'chats'
  }, {
    name: 'users'
  }, {
    name: 'academic'
  }, {
    name: 'reports'
  }, {
    name: 'forms'
  }, {
    name: 'transportion'
  }, {
    name: 'gatepass'
  }, {
    name: 'hostel'
  }, {
    name: 'enquiry'
  }, {
    name: 'exam'
  }]
}