import socket from './config'
import store from '../../store/index'
import cookie from 'vue-cookie'


// // this event fires when connection is established between the client & server
socket.on('connect', () => {
  // socket.emit('newConnection', store.state.userDetails)
  console.log('socketId: ', socket.id)
  const user = JSON.parse(cookie.get(process.env.VUE_APP_USER_META_DETA))
  if (user && user._id) {
    socket.emit('updateSocketId', user)
  }
})

socket.on('broadcast', (msg) => {
  console.log(msg, 'moduleUpdate')
})

socket.on('activeUsers', (users) => {
  store.commit('setActiveUsers', users)
  console.log(store.getters.activeUsers, 'activeUsers')
})

// socket.on('disconnect', () => {
//   let { token } = store.state
//   if (token) {
//     // store.commit('logout')
//     // this.$root.$emit('snackbar', { message: 'Logged Out Successfully', color: 'success' })
//   }
// })

export default {
  install (Vue) {
    Vue.prototype.$socket = socket
  }
}


// error-handling
socket.on('connect_failed', () => {
  console.log('Sorry, there seems to be an connection failed with the connection!')
})

socket.on('connect_error', () => {
  console.log('Sorry, there seems to be error with the connection!')
})

socket.on('Connect_timeout', () => {
  console.log('Sorry, it seems to be timeout of socket connection!')
})

socket.on('error', () => {
  console.log('Sorry, it seems to be an error with the connection!')
})

socket.on('reconnect_failed', () => {
  console.log('Sorry, Reconnection with the connection is failed!')
})
