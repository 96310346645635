import Vue from 'vue'
import VueRouter from 'vue-router'
import cookie from 'vue-cookie'

Vue.use(VueRouter)

// the below code is used to avoid reduntant same location push errors
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [{
  path: '*',
  redirect: '/login'
}, {
  path: '/',
  component: () => import(/* webpackChunkName: "promotion-page" */ '../views/PromotionPage/Index.vue'),
  meta: { noauth: true },
  beforeEnter (from, to, next) {
    if (cookie.get(process.env.VUE_APP_USER_TOKEN))  next('/dashboard')
    else next()
  }
}, {
  path: '/login',
  component: () => import('../views/Auth/Login.vue'),
  beforeEnter (from, to, next) {
    if (cookie.get(process.env.VUE_APP_USER_TOKEN))  next('/dashboard')
    else next()
  }
}, {
  path: '/signup',
  component: () => import('../views/Auth/Signup.vue'),
  meta: { noauth: true }
}, {
  path: '/privacy-policy',
  component: () => import('../views/Auth/PrivacyPolicy.vue'),
  meta: { noauth: true }
}, {
  path: '/home',
  component: () => import('../views/Landing/Home.vue')
}, {
  path: '/openform/:openId',
  component: () => import('../views/OpenForms/Index.vue'),
  meta: { noauth: true }
}, {
  path: '/',
  component: () => import('../views/Index.vue'),
  children: [{
    path: '/workspaces',
    component: () => import('../views/Workspaces/Index.vue'),
    // meta: { noauth: true }
  }, {
    path: 'dashboard',
    component: () => import(/* webpackChunkName: "dashboard-page" */ '../views/Dashboard.vue')
  }, {
    path: 'account',
    component: () => import('../views/Users/View.vue')
  }, {
    path: 'calendar',
    component: () => import('../views/Calendar/Index.vue')
  }, {
    path: 'attendance',
    component: () => import('../views/Index.vue'),
    children: [{
      path: 'student',
      component: () => import(/* webpackChunkName: "attendance-staff" */ '../views/Attendance/StudentAttendance.vue'),
    }, {
      path: 'staff',
      component: () => import(/* webpackChunkName: "attendance-student" */ '../views/Attendance/StaffAttendance.vue'),
    }, {
      path: 'manage',
      component: () => import(/* webpackChunkName: "attendance-settings" */ '../views/Attendance/Settings.vue'),
    }]
  }, {
    path: 'communication',
    component: () => import('../views/Index.vue'),
    children: [{
      path: 'chat',
      component: () => import('../views/Communication/Chat/Index.vue')
    }, {
      path: 'sms',
      component: () => import('../views/Communication/Sms/Index.vue')
    }, {
      path: 'mail',
      component: () => import('../views/Communication/Mail/Index.vue')
    }]
  }, {
    path: 'vc',
    component: () => import('../views/Index.vue'),
    children: [{
      path: '/',
      component: () => import('../views/Vc/entry.vue')
    }, {
      path: 'stream/:id',
      component: () => import('../views/Vc/stream.vue')
    }]
  }, {
    path: 'settings',
    component: () => import('../views/Settings/AppSettings.vue'),
  }, {
    path: 'payments',
    component: () => import(/* webpackChunkName: "leave-index" */ '../views/Index.vue'),
    children: [{
      path: '/',
      component: () => import(/* webpackChunkName: "leave-view" */ '../views/Payments/Dashboard.vue')
    }, {
      path: 'new',
      component: () => import(/* webpackChunkName: "leave-view" */ '../views/Payments/NewPayment.vue')
    }]
  }, {
    path: 'academic',
    component: () => import('../views/Index.vue'),
    children: [{
      path: 'student-promotion',
      component: () => import('../views/Academic/Promote.vue')
    }, {
      path: 'subjects',
      component: () => import('../views/Academic/SubModules/Subjects.vue')
    }, {
      path: 'courses',
      component: () => import('../views/Academic/SubModules/Courses.vue')
    }, {
      path: 'routine-planner',
      component: () => import('../views/Academic/SubModules/SchedulePlanner.vue')
    }, {
      path: 'classrooms',
      component: () => import('../views/Index.vue'),
      children: [{
        path: '/',
        component: () => import('../views/Academic/Classrooms.vue')
      }, {
        path: ':id',
        component: () => import('../views/Academic/Detail.vue')
      }, {
        path: 'config/:id',
        component: () => import('../views/Academic/Config.vue')
      }]
    }]
  }, {
    path: 'library',
    component: () => import('../views/Index.vue'),
    children: [{
      path: 'books',
      component: () => import('../views/Library/Books.vue')
    }, {
      path: 'issue-book',
      component: () => import('../views/Library/IssueBooks.vue')
    }, {
      path: 'settings',
      component: () => import('../views/Library/Settings.vue')
    }]
  }, {
    path: 'leave',
    component: () => import(/* webpackChunkName: "leave-index" */ '@/views/Index.vue'),
    children: [{
      path: '/',
      component: () => import(/* webpackChunkName: "leave-view" */ '../views/Leave/View.vue')
    }, {
      path: 'manage',
      component: () => import(/* webpackChunkName: "leave-manage" */ '../views/Leave/Manage.vue')
    }]
  }, {
    path: 'enquiry',
    component: () => import('../views/Enquiry/Index.vue'),
  }, {
    path: 'gate-pass',
    component: () => import('../views/GatePass/Index.vue'),
  }, {
    path: 'transportion',
    component: () => import('../views/Index.vue'),
    children: [{
      path: '/',
      component: () => import('../views/Transport/Vehicles.vue')
    }, {
      path: 'routes',
      component: () => import('../views/Transport/Vehicle_Routes.vue')
    }]
  }, {
    path: 'hostel',
    component: () => import('../views/Index.vue'),
    children: [{
      path: '/',
      component: () => import('../views/Hostel/Dashboard.vue')
    }, {
      path: 'list',
      component: () => import('../views/Hostel/HostelsList.vue')
    }, {
      path: 'manage/:id?',
      component: () => import('../views/Hostel/Manage.vue')
    }]
  }, {
    path: 'form-datas/:id',
    component: () => import('../views/ModuleFormResults/Index.vue'),
  }, {
    path: 'reports',
    component: () => import('../views/Reports/Index.vue'),
  }, {
    path: 'contact_developer',
    component: () => import('../views/Contact/DeveloperContact.vue')
  }, {
    path: '/sp-callback',
    component: () => import('../views/Tenants/SharepointCallBack.vue')
  }, {
    path: '/g-callback',
    component: () => import('../views/Tenants/GoogleCallBack.vue')
  }, {
    path: 'tenants',
    component: () => import('../views/Index.vue'),
    children: [{
      path: '/',
      component: () => import('../views/Tenants/List.vue')
    }, {
      path: 'actions/:id?',
      component: () => import('../views/Tenants/Action.vue')
    }]
  }, {
    path: 'forms',
    component: () => import('../views/Index.vue'),
    children: [{
      path: '/',
      component: () => import('../views/FormBuilder/List.vue')
    }, {
      path: 'actions/:id?',
      component: () => import('../views/FormBuilder/Action.vue')
    }]
  }, {
    path: 'users_roles',
    component: () => import('../views/Index.vue'),
    children: [{
      path: '/',
      component: () => import('../views/Users/UserRoles.vue')
    }, {
      path: ':role_id/users',
      component: () => import('../views/Users/UsersList.vue')
    }, {
      path: ':role_id/users/actions/:id?',
      component: () => import('../views/Users/Action.vue')
    }, {
      path: ':role_id/users/view/:id',
      component: () => import('../views/Users/View.vue')
    }]
  }, {
    path: 'exam',
    component: () => import('../views/Index.vue'),
    children: [{
      path: '/',
      component: () => import('../views/Exams/List.vue')
    }, {
      path: 'actions/:id?',
      component: () => import('../views/Exams/Action.vue')
    }]
  }, {
    path: 'admissions',
    component: () => import('../views/Index.vue'),
    children: [{
      path: '/',
      component: () => import('../views/Admissions/List.vue')
    }, {
      path: ':id?',
      component: () => import('../views/Admissions/Action.vue')
    }]
  }]
}, {
  path: '/techilex-contactus',
  component: () => import('@/views/Techilex/Contactus.vue')
}]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  let token = cookie.get(process.env.VUE_APP_USER_TOKEN)
  if (to.meta && to.meta.noauth) next()
  else {
    if(!to.path.includes('/login')) {
      if (!to.path.includes('/home')) {
        if (token) next()
        else next('/login')
      } else next ()
    } else next()
  }
})

export default router
