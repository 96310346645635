import { api } from '@/plugins/axios'

export default {
  // updateTabDetails(state) {
  //   if (state.state.appSettings) {
  //     if (state.state.appSettings.name) document.querySelector('title').textContent = state.state.appSettings.name
  //     if (state.state.appSettings.logo) {
  //       var link = document.querySelector("link[rel*='icon']") || document.createElement('link')
  //       link.type = 'image/x-icon'
  //       link.rel = 'shortcut icon'
  //       link.href =  state.state.appSettings.logo
  //       document.getElementsByTagName('head')[0].appendChild(link)
  //     }
  //   }
  // },
  getSubjects({ state }) {
    return new Promise((resolve) => {
      api.get('subjects').then(({ data }) => {
        state.listOfSubjects = data
        resolve(data)
      })
    })
  },
  getCourses({ state }) {
    return new Promise((resolve) => {
      api.get('courses').then(({ data }) => {
        state.listOfCourses = data
        resolve(data)
      })
    })
  },
  getUserRoles({ state }) {
    return new Promise((resolve) => {
      api.get('user_roles').then(({ data }) => {
        state.listOfRoles = data
        resolve(data)
      })
    })
  },
  getUsersList ({ state }, { only, except, roleIds, lookupShift }) { // eslint-disable-line
    return new Promise((resolve) => {
      let query = [{
        $lookup: {
          from: 'UserRoles',
          foreignField: '_id',
          localField: 'role',
          as: 'role'
        }
      }, {
        $set: {
          role: {
            $arrayElemAt: [ '$role', 0 ]
          }
        }
      }]
      if (only) query.push({
        $match: {
          'role.code': {
            '$in': only
          }
        }
      })
      if (except) query.push({
        $match: {
          'role.code': {
            $nin: except
          }
        }
      })
      if (roleIds) {
        query.push({
          $set: {
            'role._id': { $convert: { input: '$role._id', to: 'string' } }
          }
        })
        query.push({
          $match: {
            'role._id': {
              $in: roleIds
            }
          }
        })
      }
      if (lookupShift) {
        query = [...query, ...[{
          $set: {
            shiftIds: {
              $map: {
                input: '$formdata.shift',
                as: 'id',
                in: {
                  '$toObjectId': '$$id'
                }
              }
            }
          }
        }, {
          $lookup: {
            from: 'Shifts',
            let: {
              ids: '$shiftIds'
            },
            pipeline: [
              {
                $match: {
                  $expr: {
                    $in: [
                      '$_id',
                      '$$ids'
                    ]
                  }
                }
              }
            ],
            as: 'shift'
          }
        }, {
          $unwind: {
            path: '$shift', 
            preserveNullAndEmptyArrays: true
          }
        }]]
      }
      api.post(`users/query`, query)
        .then(({ data }) => {
          resolve(data)
        })
    })
  }
}
