export const no = {
  from: 'From',
  to: 'To',
  today: 'Today',
  date: 'Date',
  time: 'Time',
  doYouReallyWantToDeleteThisRecord: 'Do you want to delete this record',
  deleteConfirmation: 'Delete Confirmation',
  delete: 'Delete',
  cancel: 'Cancel'
}
