export default {
  listOfModules (state) {
    return state.listOfModules
  },
  filterItem (state) {
    return (name) => {
      return state.filters[name]
    }
  },
  activeUsers (state) {
    return state.activeUsers
  }
}