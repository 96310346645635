import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router/index'
import store from './store'
import vuetify from './plugins/vuetify'
import cookie from './plugins/cookie'
import moment from './plugins/moment'
import jinglee from './plugins/jinglee'
import socket from './plugins/socketIO/socket'
import mixin from './mixins/mixins'
import FullCalendar from 'vue-full-calendar'
import i18n from './lang/config'
import 'leaflet/dist/leaflet.css'
import L from 'leaflet'

delete L.Icon.Default.prototype._getIconUrl

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})

// mixins
const mixins = { mixin }
Object.keys(mixins).forEach((key) => {
  Vue.mixin(mixins[key])
})

// plugin registration
const plugins = { cookie, moment, jinglee, FullCalendar, socket }
Object.keys(plugins).forEach((key) => {
  Vue.use(plugins[key])
})

// event-bus
const eventBus = new Vue()
Vue.prototype.$eventBus = eventBus
export default eventBus


Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
