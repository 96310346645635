import axios from 'axios'
import VueCookie from 'vue-cookie'
import socket from './socketIO/config'
import store from '../store/index'
import eventBus from '../main'
import moment from 'moment'
import momentTz from 'moment-timezone'

let timezone = localStorage.getItem('x-timezone')
timezone = timezone || Intl.DateTimeFormat().resolvedOptions().timeZone

const api = axios.create({
  baseURL: process.env.VUE_APP_URL,
  headers: {
    common: {
      'x-sms-token': VueCookie.get(process.env.VUE_APP_USER_TOKEN),
      'x-timezone': timezone
    }
  }
})

const $_utcToLocal = (date, fromFormat, toFormat) => { // eslint-disable-line camelcase
  if (!date) return null

  let timezone = localStorage.getItem('x-timezone')
  timezone = timezone || Intl.DateTimeFormat().resolvedOptions().timeZone
  return (date) ? momentTz.utc(date, (fromFormat || 'YYYY-MM-DDTHH:mm:ss')).tz(timezone).format((toFormat || 'YYYY-MM-DDTHH:mm:ss')) : null
}

const $_recursiveUpdate = (obj, options) => { // eslint-disable-line
  if (obj && (typeof obj === 'object')) {
    if (Array.isArray(obj) && obj.length) {
      for (const arrayItemIndex in obj) {
        obj[arrayItemIndex] = $_recursiveUpdate(obj[arrayItemIndex], options)
      }
      return obj
    } else if ((typeof obj === 'object') && Object.keys(obj).length) {
      const newobj = {}
      for (const key in obj) {
        if (obj[key] && (typeof obj[key] === 'object')) newobj[key] = $_recursiveUpdate(obj[key], options)
        else {
          if ((obj[key] && typeof obj[key] === 'string')) {
            if (options.utcToLocal) {
              if (moment(obj[key].split('.')[0], 'YYYY-MM-DDTHH:mm:ss', true).isValid()) newobj[key] = $_utcToLocal(obj[key])
              else newobj[key] = obj[key]
              // console.log(key, obj[key], newobj[key])
            } else newobj[key] = obj[key]
          } else newobj[key] = obj[key]
        }
      }
      return newobj
    } else return obj
  } else return obj
}

api.interceptors.request.use((req) => {
  if (['post', 'put', 'delete'].includes(req.method)) {
    if (!req.url.includes('/query') && !req.url.includes('/filter')) {
      socket.emit('moduleUpdate', req.url.split('/')[0])
    }
  }
  return req
})

api.interceptors.response.use((res) => {
  const { data } = res
  if (res.status.toString().charAt(0) === '2' && res.data) res.data = $_recursiveUpdate(JSON.parse(JSON.stringify(data)), { utcToLocal: true })
  return res
}, (error) => {
  if (!error || !error.response) return Promise.reject(Error('Server error'))

  let { status, data, statusText } = error.response
  if (status >= 400) {
    switch (status) {
      case 401:
        store.commit('logout')
        eventBus.$emit('snackbar', {
          message: ((data.err) ? data.err.message : (data || statusText)),
          color: 'error darken-1'
        })
        break
      default:
        eventBus.$emit('snackbar', {
          message: ((data.err) ? data.err.message : (data || statusText)),
          color: 'error darken-1'
        })
        break
    }
  }

  return Promise.reject(error)
  // if (response.data.err) {
  //   let { status, message } = response.data.err
  //   if (status === 401) { // handle unauthorized
  //     store.commit('logout')
  //   } else if (status >= 400) {
  //     if (status === 429) {
  //       console.log('Too many requests')
  //       eventBus.$emit('snackbar', { message: 'Too many requests, please try again later', color: 'error' })
  //     } else {
  //       eventBus.$emit('snackbar', { message, color: 'error' })
  //     }
  //   }
  // }
})

// function getParameterByName(name, url) {
//   name = name.replace(/[[\]]/g, '\\$&');
//   var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
//       results = regex.exec(url);
//   if (!results) return null;
//   if (!results[2]) return '';
//   return decodeURIComponent(results[2].replace(/\+/g, ' '));
// }

export { api }
