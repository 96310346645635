import cookie from 'vue-cookie'
import { api } from '@/plugins/axios'
import router from '../router'
import socket from '@/plugins/socketIO/config'

export default {
  workspacelogin (state, { token, payload }) {
    let userDetails = { ...payload, isTenantAuthorized: false }
    cookie.set(process.env.VUE_APP_USER_META_DETA, JSON.stringify(userDetails))
    cookie.set(process.env.VUE_APP_USER_TOKEN, token)
    api.defaults.headers['x-sms-token'] = token
    state.token = token
    state.userDetails = userDetails
    router.push('/workspaces')
  },
  login (state, { token, user, appconfig }) {
    user.isTenantAuthorized = true
    if (user && user.profile) user.profile = `${process.env.VUE_APP_IMAGE_URL}profile/${user.profile || 'default.jpg'}`
    api.defaults.headers['x-sms-token'] = token
    const timezone = (appconfig && appconfig.timezone) ? appconfig.timezone : Intl.DateTimeFormat().resolvedOptions().timeZone
    localStorage.setItem('x-timezone', timezone)
    api.defaults.headers['x-timezone'] = timezone
    user = { ...user, timezone }

    cookie.set(process.env.VUE_APP_USER_META_DETA, JSON.stringify(user))
    cookie.set(process.env.VUE_APP_USER_TOKEN, token)
    state.token = token
    state.userDetails = user
    state.tenant = user.tenant
    if (state.userDetails) router.push('/dashboard')
  },
  logout (state) {
    if (state && state.userDetails) socket.emit('logout', state.userDetails)
    cookie.delete(process.env.VUE_APP_USER_META_DETA)
    cookie.delete(process.env.VUE_APP_USER_TOKEN)
    router.push('/login')
    state.tenant = state.token = ''
    state.metaData = state.userDetails = null
  },
  restoreAuthDetails (state) {
    state.token = cookie.get(process.env.VUE_APP_USER_TOKEN)
    const userDetails = cookie.get(process.env.VUE_APP_USER_META_DETA) ? JSON.parse(cookie.get(process.env.VUE_APP_USER_META_DETA)) : null

    state.userDetails = userDetails
    state.tenant = (state.userDetails) ? state.userDetails.tenant : null

    if (userDetails && userDetails.role) {
      api.get(`user_roles/${userDetails.role._id}`).then(({ data }) => {
        userDetails.role = data
        state.userDetails = userDetails
        state.tenant = state.userDetails ? state.userDetails.tenant : null
      })
    }
  },
  updateUserAccount (state, data) {
    state.userDetails = data
    let userMetaDetails = cookie.get(process.env.VUE_APP_USER_META_DETA) ? JSON.parse(cookie.get(process.env.VUE_APP_USER_META_DETA)) : null
    if (userMetaDetails) {
      userMetaDetails.userDetails = data
      cookie.set(process.env.VUE_APP_USER_META_DETA, JSON.stringify(userMetaDetails))
    }
  },
  updateUserProfile (state, data) {
    state.profile = `${process.env.VUE_APP_IMAGE_URL}profile/${state.profile || 'default.jpg'}`
    let userMetaDetails = cookie.get(process.env.VUE_APP_USER_META_DETA) ? JSON.parse(cookie.get(process.env.VUE_APP_USER_META_DETA)) : null
    if (userMetaDetails) {
      userMetaDetails.userProfile = data
      cookie.set(process.env.VUE_APP_USER_META_DETA, JSON.stringify(userMetaDetails))
    }
  },
  updateTabLogo (state, data) {
    state.tabLogo = `${ process.env.VUE_APP_IMAGE_URL }logo/${data}`
    let userMetaDetails = cookie.get(process.env.VUE_APP_USER_META_DETA) ? JSON.parse(cookie.get(process.env.VUE_APP_USER_META_DETA)) : null
    if (userMetaDetails) {
      userMetaDetails.appLogo = data
      cookie.set(process.env.VUE_APP_USER_META_DETA, JSON.stringify(userMetaDetails))
    }
  },
  updateAppSettings (state, data) {
    state.appSettings = data
    let userMetaDetails = cookie.get(process.env.VUE_APP_USER_META_DETA) ? JSON.parse(cookie.get(process.env.VUE_APP_USER_META_DETA)) : null
    if (userMetaDetails) {
      userMetaDetails.appSettings = data
      cookie.set(process.env.VUE_APP_USER_META_DETA, JSON.stringify(userMetaDetails))
    }
  },
  updateUserRoles (state, data) {
    state.userRoles = data
  },
  getUserRoles (state) {
    api.get('user_roles').then(response => {
      state.userRoles = response.data
    })
  },
  updateFilter (state, { name, payload }) {
    state.filters[name] = payload
  },
  setActiveUsers (state, data) {
    state.activeUsers = data
  }
}