<template>
  <v-app>
    <v-main :style="`background-color: ${($vuetify.theme.dark) ? '' : '#eef0f3'}`">
      <layout></layout>
      <nav-bar v-if="token && userDetails.isTenantAuthorized"></nav-bar>
      <app-view :key="$route.fullPath"></app-view>
    </v-main>
  </v-app>
</template>
<script>
import store from '@/store'
export default {
  data() {
    return {}
  },
  components: {
    'nav-bar': () => import('./components/navbar.vue'),
    'app-view': () => import('@/views/Index.vue'),
    'layout': () => import('@/components/Layout.vue')
  },
  created () {
    this.$store.commit('restoreAuthDetails')

    // shortcuts handler
    document.onkeydown = (e) => {
      e = e || window.Event
      const code = e.which || e.keyCode // Get key code
      if (![27].includes(code) && (!e.altKey)) return
      if (window.document.URL.includes('/login')) return
      switch (code) {
        case 76: // { alt + l }
          e.preventDefault()
          e.stopPropagation()
          store.commit('logout')
          break
        case 68: // { alt + d }
          this.$vuetify.theme.dark = !this.$vuetify.theme.dark
          break
      }
    }
  },
  mounted () {
    // these below lines are for error-handling testing #experimental
    // window.addEventListener('error', () => {
    //   console.log('received an error!')
    // })
    // window.onerror = () => {
    //   console.log('got an error!')
    // }
  }
}
</script>
<style>
  html {
    -ms-overflow-style: none !important;
    scrollbar-width: none !important;
    /* user-select: none !important; */
  }
  html::-webkit-scrollbar {
    display: none;
  }

  /* vuetify elements */
  .v-input--switch {
    margin: 5px 10px 0 5px !important;
  }
  .v-input .v-label {
    font-size: 0.85rem !important;
  }
  .v-messages__message {
    font-size: 11px !important;
  }
  .v-input--selection-controls {
    padding-top: 0 !important;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type=number] {
    -moz-appearance: textfield;
  }
  .v-input__append-inner .v-icon.mdi-menu-down {
    margin-right: -12px !important;
  }

  /* v-text-field */
  .v-input .v-input__control {
    font-size: 0.93rem !important;
  }
  .v-text-field__details {
    padding: 0 5px !important;
  }

  /* v-select */
  .v-select-list, .v-list {
    padding: 3px 0 !important;
    min-height: 30px !important;
  }
  .v-list-item:not(.app-navbar .v-list-item) {
    min-height: 34px !important;
    max-height: 40px !important;
    padding-left: 12px !important;
  }
  .v-list-item__content, .v-list-item__title {
    padding: 7px 0px !important;
    font-size: 0.80rem !important;
  }
  .v-list-item__icon {
    margin: 5.5px 13px 0 0 !important;
  }
  .v-list-item__action {
    margin: 0 10px 0 0 !important;
  }

  /* v-btn */
  button.v-btn {
    text-transform: capitalize !important;
    height: 35px !important;
    /* padding: 0 12px !important; */
    font-size: 0.82rem !important;
  }

  /* v-toolbar */
  .v-toolbar__content {
    padding: 4px 12px !important;
  }

  /* v-date-picker */
  .v-date-picker-table {
    height: 100% !important;
  }

  /* custom styles */
  .cursor-pointer {
    cursor: pointer;
  }

  /* Scrollbar css for auto complete */
  .v-menu__content::-webkit-scrollbar {
    width: 10px;
  }
  .v-menu__content::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
  .v-menu__content::-webkit-scrollbar-thumb {
    background: #888; 
  }
  .v-menu__content::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }

  /* scrollbar css for dialog */
  .v-dialog::-webkit-scrollbar {
    width: 10px;
  }
  .v-dialog::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
  .v-dialog::-webkit-scrollbar-thumb {
    background: #888; 
  }
  .v-dialog::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }
  .bordered--table table thead th td, .bordered--table table tbody tr td {
    border: 1px solid rgba(0,0,0,0.12);
  }
</style>
