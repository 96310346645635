export default {
  install (Vue) {
    Vue.prototype.$jinglee = {
      getRandomId () {
        return Math.floor(Math.random() * (10000 - 100)) + 100
      },
      getBinary (file) {
        let form = new FormData()
        form.append('file', file)
        return form
      }
    }
  }
}