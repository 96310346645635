export const en = {
  // Date and Time
  from: 'From',
  to: 'To',
  today: 'Today',
  date: 'Date',
  time: 'Time',
  // Layout
  doYouReallyWantToDeleteThisRecord: 'Do you want to delete this record',
  deleteConfirmation: 'Delete Confirmation',
  delete: 'Delete',
  cancel: 'Cancel',
  // Academic
  class: 'Class',
  student: 'Student'
}
